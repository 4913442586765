.product-information {
    @include ml-56;
    @include breakpoint(medium down) {
        @include ml-8;
    }
    @include breakpoint(small down) {
        margin-left: 0;
    }

    .prices {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid $dark-grey;
        display: flex;
        justify-content: space-evenly;

        .retail-price {
            @include ml-24;
            @include breakpoint(small down) {
                @include ml-8;
            }

            .price-label {
                display: block;
                font-size: 10px;
                text-transform: uppercase;
            }
        }
    }
}

.rp-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.line-through {
    font-size: 18px;
    text-decoration: line-through;
    color: $dark-grey;
    span {
        color: $dark-grey;
    }
}

.retail-price .line-through span {
    color: $dark-grey;
}

#product-slider {
    @include breakpoint(small down) {
        @include mb-24;
    }
}

#product-slider-nav {
    display: flex;
    flex-direction: column;
}

.addtocart-form {
    width: 100%;
    @include mt-16;

    &.product-page {
        border-bottom: 1px solid $dark-grey;
        @include pb-24;
    }

    .btn {
        width: 100%;
        @include ml-16;
        @include mr-16;
        @include breakpoint(medium down) {
            @include mb-24;
        }

        .cart-icon {
            width: 16px;
            @include ml-8;
        }
    }
}

.add-to-cart-button {
    @include mt-8;
}

.product-description {
    @include mt-24;
}

.related-title {
    text-align: center;
    color: $blue;
    margin: 56px 0 8px;
}

.product-slide-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &.arrow-right {
        right: -10px;
    }
    &.arrow-left {
        left: -10px;
    }
}

.product-sku {
    margin-top: 10px;
    @include breakpoint(small down) {
        font-size: 14px;
    }
}

table.table-sizes,
table.qty-table {
    border-collapse: collapse;

    .table-heading {
        background-color: $white;
        border: none;

        th {
            border-bottom: 1px solid $grey-2;
            color: $black;
            text-align: center;
            font-size: 14px;
            line-height: 24px;
            @include muli-bold;
        }
    }
}

.table-body {
    border: none;

    tr:nth-child(even) {
        background-color: $white;
        border: none;
    }

    tr:nth-child(odd) {
        background-color: $light-grey;
        border: none;
    }

    td {
        color: $dark-grey;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        padding: 4px 0;
        @include muli-regular;
    }
}

.sizes-table {
    background-color: $blue;
    display: inline-block;
    padding: 8px 24px;
    @include mt-8;
    font-size: 12px;
    border-radius: 6px;
    @include basic-transition;

    &:hover {
        background-color: $blue-hover;
    }
}

.list-tag {
    @include mt-32;
    li {
        display: inline-block;
        @include mb-8;
    }
}

.product-separator {
    margin: 32px 0 32px;
    border: none;
    border-top: 1px solid $dark-grey;
}

#related-slider .product-container .grid-size-list {
    display: none;
}

.best-price-div {
    display: flex;
    flex-direction: column;
}

.discount-p {
    font-size: 18px;
    margin-left: 5px;
    font-weight: 600;
}

.product-tabs-wrapper {
    background-color: $blue;
    padding-bottom: 25px;
}

.detail {
    &.-right {
        text-transform: uppercase;
        margin-bottom: 8px;
        font-weight: 600;
    }
}

.euro {
    color: $black;
    &.retail {
        color: $dark-grey;
    }
}

.i-number-btn {
    cursor: pointer;
    border: 1px solid $black;
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: $white;
    @include pl-8;
    @include pr-8;

    img {
        width: 16px;
    }

    &.i-number-minus {
        border-right: none;
    }

    &.i-number-plus {
        border-left: none;
    }
}

.qty-input-container {
    display: flex;
    align-items: initial;
    justify-content: center;
    position: relative;
}

.i-number {
    margin: 0;
    box-shadow: none;
    text-align: center;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    border-left: none;
    border-right: none;
    background-color: $white;
    width: calc(100% - 80px);
    height: 40px;
    color: $dark-grey;
    font-size: 16px;
    line-height: 24px;
    @include muli-regular;
    cursor: default;

    &.cart {
        width: calc(100% - 144px);
    }
}

.product-info {
    border-bottom: 1px solid $dark-grey;
    @include pb-24;

    .product-brand,
    .product-name {
        color: $black;
        display: inline;
        font-size: 35px;
        line-height: 44px;
    }

    .product-brand {
        @include muli-bold;
    }

    .product-name {
        @include muli-regular;
    }

    &.list {
        .product-brand,
        .product-name {
            font-size: 20px;

            @include breakpoint(medium down) {
                font-size: 16px;
            }
        }
    }

    .product-code {
        color: $dark-grey;
        font-size: 14px;
        line-height: 24px;
        @include muli-regular;
    }

    .price-container {
        .title-container {
            display: flex;
            @include mt-8;

            .title {
                display: inline;
                margin-left: auto;
                color: $grey-2;
                font-size: 12px;
                line-height: 12px;
                @include muli-regular;
            }
        }

        .prices {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .street-price {
                display: inline-block;
                font-size: 35px;
                line-height: 35px;
                @include muli-bold;
                @include breakpoint(medium down) {
                    font-size: 24px;
                    line-height: 24px;
                }
            }

            .retail-price,
            .retail-price .euro {
                display: inline-block;
                color: $grey-2;
                font-size: 25px;
                line-height: 25px;
                @include muli-regular;
                @include breakpoint(medium down) {
                    font-size: 16px;
                    line-height: 16px;
                }
            }

            .retail-price {
                margin-top: auto;
                margin-left: auto;
                @include breakpoint(medium down) {
                    margin-top: 0;
                }
            }

            &.placeholder {
                justify-content: flex-end;
            }
        }
    }
}

.discounted {
    color: $grey-2;
    text-decoration: line-through;
    @include mr-8;

    .euro {
        color: $grey-2;
    }
}

.catalog-rules {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include mt-16;

    .catalog-rule {
        color: $grey-2;
        border: 1px solid $grey-2;
        font-size: 14px;
        @include muli-regular;
        @include pl-8;
        @include pr-8;
        @include mr-8;
        @include breakpoint(medium down) {
            font-size: 12px;
        }
    }
}

.product-color-swatch {
    border-bottom: 1px solid $dark-grey;

    .color-option {
        display: inline-block;
        padding: 2px;
        width: 30px;
        height: 30px;
        @include mt-16;
        @include mb-16;

        .color {
            width: 100%;
            height: 100%;
        }

        &.selected,
        &:hover {
            border: 1px solid $black;
        }
    }
}

.qty-max {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: 1px solid $black;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 1px;
    @include ml-8;
    position: relative;

    &:active {
        top: 1px;
    }
}

.product-image-scroller {
    .product-image {
        display: flex;
        align-items: center;
        justify-content: center;
        @include mb-16;
    }
}

.box-img {
    height: 360px;

    @include breakpoint(medium only) {
        height: 270px;
    }
}

//bollino promo
.stiker-promo {
    min-width: 48px;
    min-height: 48px;
    padding: 8px;
    background-color: $red;
    position: absolute;
    z-index: 10;
    border-radius: 8px;
    right: 1rem;
    top: 1.5rem;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;

    &.black {
        background-color: $black;
    }

    &.list {
        right: 25px;
        top: 0.5rem;
    }

    &.grid {
        right: 10px;
        top: 0.5rem;
    }

    &.list.black {
        right: 28px;
        top: 2.5rem;
    }
}

.products-row {
    .detail {
        overflow: hidden;
        .sticker-label {
            left: -35%;
        }
    }
}

.single-cart-product {
    overflow: hidden;
    
    .product-detail {
        .sticker-label {
            top: 20%;
            @include breakpoint(small down) {
                .text-label-sticker {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
}

.product-picture {
    overflow: hidden;
}

.product-page {
    .detail {
        overflow: hidden;
        .sticker-label {
            top: 2%;
            left: -35%;
        }
    }

    .sticker-label {
        @include breakpoint(small down) {
            .text-label-sticker {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}

.sticker-label {
    text-transform: uppercase;
    position: absolute;
    top: 15%;
    left: -30%;
    transform: rotate(-45deg);
    width: 100%;
    text-align: center;
    z-index: 4;

    .text-label-sticker {
        font-size: 12px;
        line-height: 16px;
    }

    @include breakpoint(small down) {
        .text-label-sticker {
            font-size: 8px;
            line-height: 12px;
        }
    }
}

.text-promo {
    color: $blue;
    font-size: 16px;
    line-height: 24px;
    @include muli-bold;

    &.white {
        color: $white;
    }
    &.dropshipping {
        text-transform: uppercase;
        color: $red;
        font-size: 18px;
        line-height: 32px;
    }
}

//label
.sticker-promo-label {
    position: absolute;
    z-index: 4;
    right: 0.2rem;
    bottom: 0px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;

    &.catalog {
        right: -13px;
        bottom: -15px;
    }

    &.list {
        right: 4px;
        bottom: 0px;
    }
}

//griglia prodotti

.prices-box {
    border-top: 1px solid $border;
    @include pt-8;
}

.pictures-container {
    .picture {
        padding: 0;
        opacity: 0.7;
        @include mb-16;

        img {
            width: 100%;
        }

        &.selected {
            opacity: 1;
        }
    }
}

.product-page {
    @include mt-32;

    .description-title {
        font-size: 16px;
        line-height: 29px;
        @include muli-bold;
        @include mb-8;
    }

    .description-text {
        @include mb-16;

        span {
            font-size: 14px;
            line-height: 24px;
            @include muli-regular;
        }

        ul > li::before {
            content: "\2022";
            margin-right: 4px;
        }
    }

    .product-tags {
        @include mb-56;

        .product-tag {
            @include mb-8;

            .tag-name,
            .tag-description {
                font-size: 14px;
                line-height: 24px;
            }

            .tag-name {
                @include muli-bold;
            }

            .tag-description {
                color: $dark-grey;
                @include muli-regular;
            }
        }
    }

    .category-tags {
        display: flex;
        border-bottom: 1px solid $dark-grey;
        @include pb-24;

        .category-tag {
            display: inline-block;
            height: 24px;
            color: $dark-grey;
            border: 1px solid $dark-grey;
            font-size: 12px;
            line-height: 24px;
            @include muli-regular;
            @include mr-16;
            @include pl-8;
            @include pr-8;

            &:hover {
                background-color: $grey-2;
                color: $white;
            }
        }
    }

    .shipping-info {
        cursor: pointer;
        border-bottom: 1px solid $dark-grey;

        .shipping-info-title {
            display: flex;
            font-size: 16px;
            @include muli-bold;
            @include pt-24;
            @include pb-24;

            .text {
                margin-right: auto;
            }

            .arrow {
                margin-left: auto;
                transform: rotate(180deg);
            }
        }

        .shipping-info-links {
            display: none;
        }

        &.open {
            .arrow {
                transform: rotate(0deg);
            }

            .shipping-info-links {
                display: block;
            }
        }
    }

    .picture-slide img {
        width: 100%;
    }
}

.product-page-sliders {
    @include mb-56;

    .slider-row {
        @include mt-40;
    }
}

.product-image-scroller {
    overflow: hidden;
}

.catalog-product {
    @include mb-24;
}

.product-picture-modal {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    overflow: auto;
    z-index: $header-z + 2;
    background-color: $white;

    &.show {
        display: block;
        @include breakpoint(medium down) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .modal-slide {
        display: none;

        &.selected {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .modal-arrow-left,
    .modal-arrow-right {
        cursor: pointer;
        position: fixed;
        top: 50%;
        width: 32px;
        @include breakpoint(medium down) {
            width: 16px;
        }
    }

    .modal-arrow-left {
        left: 32px;
        @include breakpoint(medium down) {
            left: 64px;
        }
    }

    .modal-arrow-right {
        right: 32px;
        @include breakpoint(medium down) {
            right: 64px;
        }
    }

    .modal-close {
        cursor: pointer;
        position: fixed;
        top: 10%;
        right: 80px;
        width: 48px;
        @include breakpoint(medium down) {
            top: 5%;
            right: 32px;
        }
    }
}

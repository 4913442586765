$size-list-width: 100px;

.container-catalog {
    @include pt-8;
    @include breakpoint(small down) {
        @include pt-24;
    }
}

.btn-catalog {
    width: 100%;
    border-radius: 0 !important;
}

.product-container {
    &.expanded {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-link {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 10;
    }

    .product-img {
        overflow: hidden;
    }

    .price {
        display: inline-block;
        margin: 0 2px;
        @include muli-bold;
        font-size: 20px;

        .line-through {
            text-decoration: line-through;
            color: $grey-2;

            span {
                color: $grey-2;
            }
        }
    }

    .product-category {
        text-transform: capitalize;
        font-size: 16px;
    }

    .product-brand {
        font-size: 16px;
        line-height: 24px;
        @include muli-bold;
    }

    .product-name {
        font-size: 14px;
        line-height: 24px;
        @include muli-regular;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }

    .grid-size-list {
        pointer-events: none;
        position: absolute;
        bottom: 0px;
        right: 0px;
        opacity: 0;
        width: 0px;
        background-color: $white;
        z-index: 5;
        text-align: center;
        transition: 0.3s;

        li {
            margin-bottom: -1px;
            font-size: 14px;
            padding: 8px 0;
        }
    }

    .grid-size-list > li:nth-child(odd) {
        background: $light-grey;
    }

    img + img {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 1;
    }

    &:hover,
    &:active {
        .grid-size-list {
            opacity: 1;
            width: 65px;
            font-weight: 600;
        }

        img + img {
            opacity: 1;
        }
    }
}

.product-image-scroller .product-image img {
    width: 100%;
    max-width: 100%;
}

.panel-group {
    border-bottom: 1px solid $black;

    .panel-collapse {
        display: none;
    }
}

.catalog-banner {
    h1 {
        padding: 12px 0;
        @include breakpoint(small down) {
            font-size: 26px;
        }
    }

    .catalog-banner-img {
        height: 300px;
        @include mb-8;
        @include background-center;
        @include breakpoint(medium down) {
            height: 26vw;
        }
        @include breakpoint(small down) {
            height: 35vw;
        }
    }

    .catalog-banner-text {
        background-color: $light-grey;
        padding: 16px 24px;
        @include breakpoint(small down) {
            display: none;
        }
    }
}

.filter-bullets {
    @include mt-16;

    .txt-right {
        @include breakpoint(small down) {
            display: flex;
            justify-content: space-between;
            @include mt-16;
        }
    }
}

// Codice vecchio
@keyframes indeterminate-bar {
    0% {
        transform: translate(-50%) scaleX(0);
    }
    50% {
        transform: translate(0%) scaleX(0.3);
    }
    100% {
        transform: translate(50%) scaleX(0);
    }
}

#layout-options {
    margin-bottom: 5px;
}

#layout-options {
    display: inline-block;
}

#catalog-container {
    .catalog-spinner {
        display: none;
        z-index: 10000;
        position: fixed;
        top: 0;
        width: 100%;
        height: 3px;
        @include breakpoint(small down) {
            top: 0;
        }

        &:after {
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation: indeterminate-bar 2s linear infinite;
            animation: indeterminate-bar 2s linear infinite;
            width: 100%;
            height: 2px;
            background-color: $blue;
            content: "";
            display: block;
        }
    }

    &.catalog-loading {
        .catalog-spinner {
            display: block;
        }

        &:before {
            content: "";
            display: block;
            position: fixed;
            z-index: 10000;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba($white, 0.5);
        }
    }
}

.remove-filter {
    font-size: 12px;
    display: block;

    img {
        margin-right: 8px;
        width: 10px;
        position: relative;
        top: -1px;
    }
}

.breadcrumb-container {
    @include mt-24;
}

.breadcrumb {
    @include breakpoint(medium down) {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: inline-block;

        img {
            width: 9px;
            height: 5px;
            transform: rotate(90deg);
        }
    }

    a {
        display: inline-block;
        padding: 0 4px;
        @include muli-regular;
    }
}

.radio-layout {
    display: inline-block;

    input {
        position: absolute;
        left: -9999px;
    }

    input:checked + label {
        .enabled {
            display: inline-block;
        }

        .disabled {
            display: none;
        }
    }

    label {
        margin: 0 2px;

        .enabled {
            display: none;
            vertical-align: text-bottom;
        }

        .disabled {
            display: inline-block;
            vertical-align: text-bottom;
        }
    }
}

.catalog-title {
    @include muli-bold;
    font-size: 30px;
    letter-spacing: 0.02em;
    color: $blue;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0px;

    &.dropshipping {
        margin-bottom: 50px;
        @include breakpoint(medium down) {
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }
}

#sort-options {
    height: 24px;
    display: inline-block;
    border: 1px solid $dark-grey;
    vertical-align: text-bottom;
    @include mr-16;
    @include pl-8;
    @include pr-8;

    .sort-btn {
        height: 24px;
        cursor: pointer;

        .text {
            font-size: 14px;
            line-height: 24px;
            color: $dark-grey;
            vertical-align: text-bottom;
            @include muli-regular;
        }

        .arrow {
            width: 9px;
            height: 5px;
            transform: rotate(180deg);
            @include ml-8;
        }
    }

    .sort-dropdown {
        display: none;
    }

    .sort-dropdown {
        position: absolute;
        z-index: 20;
        background-color: $white;
        left: 0px;
        right: 0px;
        top: 23px;
        min-width: 150px;
        border: 1px solid $dark-grey;
        text-align: left;

        a {
            font-size: 14px;
            line-height: 24px;
            color: $dark-grey;
            display: block;
            @include muli-regular;
            @include pl-8;
            @include pr-8;
        }

        a:hover,
        a:active {
            color: $white;
            background: $grey-2;
        }

        &.open {
            display: block;
        }
    }
}

.pagination-container {
    display: flex;
}

.pagination {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    @include mt-48;
    @include mb-56;

    li {
        display: inline-block;
        margin: 0;

        @include breakpoint(small down) {
            &:nth-child(n+8):not(:last-child) {
                display: none;
            }
        }
    }

    img {
        width: 8px;
        position: relative;
    }

    a {
        display: flex;
        justify-content: center;
        width: 32px;
        height: 32px;
        color: $grey-2;
        border: 1px solid $grey-2;
        font-size: 16px;
        line-height: 24px;
        padding: 0;
        @include muli-regular;
    }

    a:hover,
    .active a {
        background: $white !important;
        color: $black;
        border-color: $black;
    }

    img.left-arrow {
        transform: rotate(270deg);
    }

    img.right-arrow {
        transform: rotate(90deg);
    }
}

.subfilter-list {
    display: none;
    padding: 4px 8px;
    background-color: $light-grey;
}

.subcategory-minus {
    display: none;
}

.subfilter-open {
    .subcategory-plus {
        display: none;
    }

    .subfilter-list {
        display: block;
    }

    .subcategory-minus {
        display: block;
        position: relative;
        top: 8px;
    }
}

li.active > a {
    /* background: white;
  color:#fff; */
    .visible-active {
        display: inline !important;
    }
    .hidden-active {
        display: none !important;
    }
}

.color-checkbox {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#toggle-filters-button {
    border-radius: 0;

    img {
        width: 14px;
        @include ml-8;
    }
    
    &.open {
        img {
            transform: rotate(180deg);
        }
    }
}

#filters-dropdown {
    width: 100%;
    @include mb-32;

    .tag-search {
        margin-top: 12px;
        @include mb-16;
    }

    .filter-tag {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        position: relative;

        .filter-name {
            cursor: pointer;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-transform: capitalize;
            border-bottom: 1px solid $border;
            @include pb-16;
            @include pt-16;
            @include muli-regular;

            .arrow {
                transform: rotate(180deg);
            }
        }

        &.open {
            .filter-name {
                font-weight: 700;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    width: 20%;
                    border-bottom: 2px solid $black;
                }
            }

            .arrow {
                transform: rotate(0deg);
            }
        }

        &:not(.open) .filter-name.has-selected-filters {
            font-weight: 600;
        }
    }

    .filter {
        .checkbox {
            width: 16px;
            height: 16px;
            cursor: pointer;
            border: 2px solid $dark-grey;
            display: inline-flex;
            padding: 1px;
            vertical-align: text-bottom;

            .checked {
                width: 100%;
                height: 100%;
                background: $black;
                display: none;
                padding: 0px;
                margin: 0px;
            }
        }

        &.active {
            .checked {
                display: block;
            }
        }

        .label {
            @include ml-8;
            @include muli-regular;
        }
    }

    @include breakpoint(medium down) {
        &:not(.init) {
            height: 0;
            overflow-y: hidden;
        }
    }
}

.visible-active {
    display: none !important;
}
.hidden-active {
    display: inline;
}

.hidden-scrollbar {
    overflow-x: hidden;

    > :first-child {
        overflow-y: auto;
        max-height: 220px;
        width: calc(100% + var(--scrollbar-width, 0px));
    }
}

.apply-filter {
    text-align: center;
    color: $black;
    border-top: 2px solid #c1c1c1;
    cursor: pointer;
    @include mt-8;
    @include muli-bold;
}

.apply-size-filter {
    text-align: center;
    color: $blue;
    text-transform: uppercase;
    border-top: 2px solid #c1c1c1;
    padding-top: 13px;
    cursor: pointer;
}

.current-filter {
    cursor: pointer;
    display: inline-block;
    border: 1px solid $grey-2;
    font-size: 12px;
    white-space: nowrap;
    @include muli-regular;
    @include mr-16;
    @include pt-8;
    @include pb-8;
    @include pl-8;
    @include pr-8;
    @include mb-8;

    &.all {
        background: $grey-2;

        .tag {
            color: $white;
        }
    }

    .tag {
        color: $black;
    }

    .icon {
        display: inline-block;
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
        vertical-align: baseline;
        @include ml-16;
    }

    @include breakpoint(small down) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include pr-16;
        @include mb-8;

        .icon {
            width: 12px;
            height: 12px;
        }
    }
}

.grid-layout {
    &.catalog {
        @include breakpoint(small down) {
            margin-top: 47px;
        }
    }
}

.no-products {
    text-align: center;
    margin: 6vw 0 6vw;
    padding: 50px;
    @include breakpoint(medium only) {
        margin: 13vw 0 12vw;
        padding: 0px;
    }
    @include breakpoint(small down) {
        margin: 6vw 0 6vw;
        padding: 0px;
    }
}

.search-keyword {
    font-size: 35px;
    @include muli-bold;
}

.products-row {
    @include mt-16;
    @include mb-40;
    @include breakpoint(medium down) {
        margin-top: 0;
    }
}

.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include mt-40;
    @include mb-40;

    .error-404 {
        display: block;
        font-size: 100px;
        line-height: unset;
        @include muli-regular;
    }

    .page-not-found {
        display: block;
        font-size: 40px;
        line-height: unset;
        @include muli-regular;
    }

    .btn {
        display: block;
        @include mt-40;
    }
}

.no-search-found {
    h2 {
        @include muli-regular;
    }
}

.not-found-product {
    @include mt-32;
    @include mb-16;
    @include muli-regular;
}

#catalog-container #brand-banner {
    opacity: 0;

    &.ready {
        opacity: 1;
    }
}

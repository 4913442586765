footer {
  background: $black;

  .footer-top {
    background: $grey-2;
  }

  .over-footer {
    display: flex;
    align-items: center;
    height: 40px;
    @include breakpoint (medium down) {
      height: auto;
      @include pt-8;
      @include pb-8;
    }
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint (small down) {
        @include mb-16;
      }
    }

      .over-footer-icon {
        width: 24px;
        height: 24px;
        @include mr-8;
        @include breakpoint (medium down) {
          margin-left: auto;
          margin-top: auto;
          margin-bottom: auto;
        }
      }

      .over-footer-text {
        color: $white;
        font-size: 14px;
        line-height: 24px;
        @include muli-bold;
        @include breakpoint (medium down) {
          margin-right: auto;
          @include pt-8;
          @include pb-8;
        }
      }
    }


  .footer-row {
    border-bottom: solid $blue;
    @include pt-40;
    @include breakpoint (medium down) {
      padding: 0px;
      max-width: 95%;
    }

    .footer-column {
      display: block;
      @include breakpoint (medium down) {
        padding-left: 0px;
        padding-right: 0px;
      }

      .footer-column-title {
        display: block;
        color: $white;
        font-size: 16px;
        line-height: 29px;
        @include muli-semibold;
        @include pb-16;
        @include breakpoint (medium down) {
          margin-right: auto;
          padding: 0px;
        }
      }

      .footer-menu {
        display: flex;
      }

      .footer-menu-arrow-container {
        height: 16px;
        margin-top: auto;
        margin-bottom: auto;
        
        .footer-menu-arrow {
          margin-left: auto;
          width: 16px;
  
          &.open {
            transform: rotate(180deg);
          }
        }
      }

      .footer-column-items {
        display: block;
        @include pb-8;
        @include breakpoint (medium down) {
          display: none;
          @include mt-16;
        }
      }

      .footer-column-items > li {
        @include pb-8;
      }

      .footer-column-items > li > a {
        color: $white;
        font-size: 14px;
        line-height: 24px;
        @include muli-regular;
      }

      .social-media-links {
        display: flex;
        align-content: flex-start;
        width: 100%;
        @include mb-24;
        @include breakpoint (medium down) {
          align-content: space-between;
        }
    
        .social-media-icon {
          width: 24px;
          @include mr-40;
          @include breakpoint (medium down) {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }

  .footer-row {
    &.address {
      border: none;
      padding: 0px;
    }
  }

  .footer-column {
    &.padding {
      border-bottom: solid $white;
      margin: 0px;
      padding: 0px;
      @include pb-24;
    }

    &.menu {
      @include breakpoint (medium down) {
        cursor: pointer;
        border-bottom: solid $white;
        margin: 0px;
        @include pb-16;
        @include pt-16;
      }
    }

    &.other {
      @include breakpoint (medium down) {
        align-items: center;
        justify-content: center;
        text-align: center;
        display: flex;
        @include mt-24;
      }
    }
  }

  .footer-address {
    color: $white;
    font-size: 10px;
    line-height: 29px;
    @include muli-regular;
    @include pt-16;
    @include pb-32;
  }
}
.cms-wrapper {
  @include mb-56;

    h1, h2, h3 {
      @include muli-bold;
    }

    p {
      @include muli-regular;
      @include mb-8;
    }

    ul, ol { padding: 0 0 16px 24px; }
}

//landing

.landing-hero__image {
    min-height: 100vh;
    background: linear-gradient(0deg,rgba(47,45,50,0.5),rgba(47,45,50,0.9)),url('/ftp/skins/romanelli/images/home/sfondo-2.jpg');
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;

    h1 {
      font-size: 50px; 
      line-height: 56px;
      @include breakpoint(medium down) {
        font-size: 25px; 
        line-height: 32px;
       }
    }
    h3 {
      font-size: 25px;
      line-height: 32px;
      @include breakpoint(medium down) {
        font-size: 18px; 
        line-height: 24px;
       }

    }
    .landing-hero__text .form {
      padding: 24px; 
      background-color: $black; 
      display: flex; 
      align-items: center; 
      justify-content: center;
      border-radius: 10px;

      p {
        font-size: 20px;
      }
    }

    h2 {
      &.landing-date {
        font-size:50px; 
        line-height: 56px; 
        margin: 24px 0;
        @include breakpoint(medium down) {
          font-size: 28px; 
          line-height: 32px;
         }
      }
    }
}


.text-landing-container {
  background-color: $white;
  padding: 40px;
}

.form-landing {
  .newsletter-subscribe-landing {
    @include mt-24;

    .email-input {
      background: $white;
      color: $black;
      border-radius: 7px;
      height: 44px;
      @include mr-16;
      @include muli-regular;
    }

    .email-input::placeholder {
      color: $black;
      opacity: 1;
      @include muli-regular;
    }

    .checkbox-container {
      span, a {
        color: $white;

        &.red {
          color: $red;
        }
      }
    }

    .btn {
      @include mt-16;
      @include pl-40;
      @include pr-40;
    }
  }
}

#flipdown.flipdown.flipdown__theme-dark .rotor-group .rotor-group-heading:before {
  color: $white!important;
  @include muli-regular;
  font-size: 20px!important;
  line-height: 40px!important;
}



.image-center-button-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include breakpoint(small down) {
    @include mt-24;
  }
}

//banner img+text

.hero-banner {
  .main-title {
    font-size: 70px;
    line-height: 80px;
    @include breakpoint(medium down) {
      font-size: 40px;
      line-height: 56px;
    }
  }
  .main-text {
    font-size: 27px;
    line-height: 40px;
    @include muli-bold;
    @include mb-16;
    @include breakpoint(medium down) {
      font-size: 18px;
      line-height: 32px;
    }
  }
  .text-container {
    align-items: center;
    justify-content: space-evenly;
    padding: 64px;
    display: flex;
    align-content: center;
    flex-direction: column;
    height: 100%;
    @include breakpoint(medium down) {
      padding: 24px;
    }
  }
}


//faq

.faq-section {
  margin-bottom: 16px;

  .faq {
    width: 100%;

    .toggle {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px 8px;
      border-bottom: 1px solid $grey-2;
      @include breakpoint(medium down) {
        padding: 8px;
      }
    }

    .question {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      width: 100%;
      @include breakpoint(medium down) {
        font-size: 14px;
        line-height: 24px;
      }
    }
    
    .answer {
      display: none;
      width: 100%;
      padding: 16px 20px 16px 8px;
      border-bottom: 1px solid $grey-2;
    }

    &:first-child .toggle {
      border-top: 1px solid $grey-2;
    }
  }

  .faq.open .toggle {
    img.close {
      display: none;
    }
  }
  .faq:not(.open) .toggle{
    img.open {
      display: none;
    }
  }
}
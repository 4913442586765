.login-subscribe-container { 
    @include mt-40;
    @include mb-40;
    @include breakpoint (small down) {
        @include mt-24;
        margin-bottom: 0;
    }
}

#password-recover-form { display: none; }

#subscribed-container { @include pt-40; }

.login-separator {
    margin: 8px 0px;
    border-top: 1px solid $line-color;
    width: 100%;
}
.img-lista {
    width: 24px;
}

.subscribe-title {
    h2 { @include muli-bold; }
}

.subscribe-description {
    h4 { @include muli-regular; }
    b { @include muli-bold; }
}

.subscribe-list {
    margin-top: 10px;
    font-size: 18px;
    @include muli-regular;
}

.select-prefix {
    @media screen and (min-width: 40em) {
        padding-left: 0px;
    }
}

.fill-country-prefix {
    @media screen and (max-width: 39.9em) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
}

.image-container {
    padding-right: 0px;
    border: 1px solid $dark-grey;
    border-right: 0px;
    @include mb-8;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include pr-8;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.image-column {
    padding-right: 0px;
    margin-right: -5px;
}

.login-title {
    font-size: 35px;
    line-height: 40px;
    text-transform: uppercase;
    @include muli-bold;
}

//subscribe completed 
.no-products-found {
    text-align: center;
    margin: 9vw 0 10vw;
    padding: 56px;
    @include breakpoint(small down) {
      margin: 56px 0 70px;
      padding: 32px;
    }

    p {
        @include muli-regular;
    }
}

.already-customer {
    @include muli-regular;
    @include mt-16;
}
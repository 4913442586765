#profile-container {
  @include pt-40;
  @include pb-40;
}

h3 {
  @include muli-bold;
}

h4 {
  @include muli-semibold;
}

.profile-content {
 @include pl-32;
  @include breakpoint(small down) {
    padding-left: 0;
    @include mt-24;
  }

    hr {
      border: none;
      border-top: 1px solid $line-color;
      @include mt-24;
    }
}


.table-orders {
  border-collapse: collapse;

    th {
      text-align: left;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal;
      @include pb-8;
      @include muli-bold;
    }

    td {
      @include muli-regular;
    }

    tr.order-controls {
      background-color: $white;
    }

    &tbody tr:nth-child(2n){
      border-bottom: 0;
      background-color:$white;
    }

    & thead {
      border: none;
      &.row-article {
        background-color: $white;
      }
    }

    & tbody {
      border:none;
    }
}

.responsive-table {
  @include mt-16;
}

.order-row {
  cursor: pointer;
  @include basic-transition;

    &:hover { background-color: $light-grey; }

    td {
      padding: 8px 8px;
      font-size:14px;
      border-top: 1px solid $line-color;
      border-bottom: 1px solid $line-color;

      &:first-child { width: 21px; }
    }
}

.order-controls-row .columns {
  display: flex;
  align-items: center;
}

.order-row-indicator {
  @include basic-transition;
  position: relative;
  top: -1px;

    &.fa-rotate-90 {
      transform: rotate(90deg);
    }
}

.order-controls {
  display: none;
  border-left: 1px solid $blue;

    > td {
      padding: 16px 24px;
    }
    
}

.dl-horizontal {

  dt {
    width: 40%;
    float: left;
    clear: left;
  }

  dd {
    width: 60%;
    float: left;
    margin: 0;
    text-align: right;
  }
}

.order-controls-return {
  border-collapse: collapse;
  border-top: 1px solid $line-color;
    td {
      border-top: 1px solid $line-color;
      padding: 4px 8px;
      line-height: 1.43;
      vertical-align: middle;
    }

    tbody tr {
      background-color:$white;
    }
}

.btn-download {
  padding: 4px 8px;
  font-size: 13px;
  letter-spacing: 0;
  margin: 2px;
}

.new-return-request { display: none; }

.table-orders { min-width: 650px; }

.responsive-table { overflow-x: auto; }


// profile consent
.profile-privacy-row {
    h4 {
      color: $black;
      text-transform: uppercase;
      font-size: 14px;
      @include mb-8;
    }
}

.inline-checkbox {
  label {
    @include muli-regular;
  }
}

.profile-privacy-col-inputs {
  display: flex;
  justify-content: space-between;
  @include breakpoint(small down) {
    flex-direction: column;
  }
}

.profile-nav {
  color: $black;
  background: $white;
  font-size:14px;

  a {
    color: $black;
    background: $white;
    transition: all .15s ease-in-out;
    display: block;
    letter-spacing: .1em;
    margin-bottom: 0;
    text-transform: uppercase;
    padding: 16px 8px;
    @include muli-bold;

    &.active, &:hover {
      color: $white;
      background: $black;
    }
  }
}

ul.list-unstyled.profile-nav li.active {
  background-color: $blue;
  color: $blue;
  padding: 16px 8px;
}
input:disabled, input[readonly], textarea:disabled, textarea[readonly]{
  background-color: $light-grey;
}

.my-orders {
  padding-bottom: 14px;
}

//profile subscription
.subscription-type-title {
  display: flex;
  align-items: center;
}

.profile-credit {
  .title {
    @include mt-8;
  }

  .row {
    @include mt-8;
  }
}

.tools-export {
  @include mt-40;
  @include mb-40;
}

.tools-export-title {
  @include muli-bold;
}

.tools-export-description {
  @include muli-regular;
}

.avaliable-every {
  @include muli-regular;
}

.return-requests-container {
  h3 {
    @include mb-16;
    @include muli-bold;
  }

  .dl-horizontal {
    dt { @include muli-semibold; }
    dd { @include muli-regular; }

    dt:nth-of-type(even), dd:nth-of-type(even) {
      background-color: $light-grey;
    }
  }
}
.btn {
  border: 0px;
  padding: 8px 40px;
  display: inline-block;
  text-align: center;
  @include basic-transition;
  font-size: 16px;
  @include muli-regular; 
  @include mt-8;
  @include mb-8; 
  color: $white;
  cursor: pointer;

  span {
    color: inherit;
    font-size: inherit;
  }

  &.btn-primary {
    background-color: $black;
    border: 2px solid $black;
    border-radius: 6px;

    &:hover {
      background-color: $black-hover;
    }

    &.white {
      color: $black;
      background-color: $white;
    }

    &.border {
      border-color: $green;
      border-width: 3px;
    }
  }
  
  &.btn-secondary {
    color: $black;
    border: 2px solid $black;
    border-radius: 6px;

    &:hover {
      border-color: $black-hover;
      color: $black-hover;
    }

    &.white {
      color: $white;
      border-color: $white;
    }
  }

  &.btn-third {
    border:none;
    color: $black;
    text-decoration: underline;
    @include muli-semibold;
  }

  &.btn-no-padding {
    padding: 0;
  }

  &:disabled { 
    opacity: .3; 
  }

  &.btn-padding {
    padding: 8px 16px;
  }

  &.btn-bg {
    background-color: $blue;
  }
}

.border-paypal.button {
  border: 1px solid $blue;
  border-radius: 4px;
}

//arrow

.arrow-link {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  position: relative;
  display: inline-block;
}

.arrow-link:after {
  content: '>';
  margin-left: 10px;
  right: -16px;
  top: 25px;
  font-size: 18px;
  color: $blue;
}

:after {
  box-sizing: border-box;
}


// text input + label
.label-input {

    label {
      text-align: left;
      font-size: 12px;
      color: $black;
      @include muli-semibold;

      &.error{
        margin-top: -.5rem;
      }

      span { @include muli-regular; }
      a { @include muli-bold; }
    }

    input {
      border: none;
      box-shadow: none;
      border: 1px solid $dark-grey;
      margin-bottom: 8px;
      @include pt-8;
      border-radius: 6px;
      @include muli-regular;

      &:focus {
        border-color: $blue;
        box-shadow: none;

        + label {
          color: $blue;
        }
      }
    }

    input.error{
      &:focus {
        border-color: $red;
  
        + label {
          color: $red;
        }
      }
    }  

    input::placeholder {
      color: $grey-2;
      opacity: 1;
      @include muli-regular;
    }

    textarea, select { 
      border: none;
      box-shadow: none;
      border: 1px solid $dark-grey;
      margin-bottom: 10px;
      border-radius: 6px;
      @include muli-regular;
      
      &:focus {
        border-color: $blue;
        box-shadow: none;
      }
    }


    .i-number { margin: 5px 0; }
}


.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label,
  & + label a {
    position: relative;
    padding: 0;
    margin: 5px 0;

      &.is-invalid-label,
      &.is-invalid-label a,
      &.is-invalid-label span {
        color: $red;
      }
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background: none;
    border: 1px solid $black;
    position: relative;
    top: -2px;
  }

  &:hover + label:before {
    background: $black;
  }

  /*&:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }
  */

  &:checked + label:before {
    background: $black;
  }

  &:disabled + label {
    color: $blue;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: $white;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 12px;
    background: $white;
    width: 2px;
    height: 2px;
    box-shadow:
            2px 0 0 $white,
            4px 0 0 $white,
            4px -2px 0 $white,
            4px -4px 0 $white,
            4px -6px 0 $white,
            4px -8px 0 $white;
    transform: rotate(45deg);
  }
}

.styled-checkbox.white {

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background: $white;
    border: 1px solid $white;
    position: relative;
    top: -2px;
  }

  &:hover + label:before {
    background: $white;
  }

  &:checked + label:before {
    background: $white;
  }

  &:disabled + label {
    color: $blue;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: $white;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 12px;
    background: $black;
    width: 2px;
    height: 2px;
    box-shadow:
            2px 0 0 $black,
            4px 0 0 $black,
            4px -2px 0 $black,
            4px -4px 0 $black,
            4px -6px 0 $black,
            4px -8px 0 $black;
    transform: rotate(45deg);
  }
}


input[type=checkbox] + label {
  margin: 0;
  position: relative;
  cursor: pointer;
  float: left;
  font-size: 14px;
  color: $black;
  @include mb-8;
  @include breakpoint(small down) {
    font-size:14px;
  }

  a {
    color: $black;

    &.red { color: $red; }
    &.blue { color: $blue; }
    &.white { color: $white; }
  }
}

input[type=checkbox] + label {
  margin: 0;
  position: relative;
  cursor: pointer;
  float: left;
  font-size: 14px;
  color: $black;
  @include mb-8;
  @include breakpoint(small down) {
    font-size:14px;
  }

  &.error{
    color:$red;
    &:before{
      border:1px solid $red;
    }
  }

  a {
    color: $black;

    &.red { color: $red; }
    &.blue { color: $blue; }
    &.white { color: $white; }
  }
}

// radio button
.radio {
  margin: 0.1rem 0;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      line-height: 24px;
      margin: 0;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      &:before {
        content: '';
        background: white;
        border-radius: 100%;
        border: 1px solid darken($black, 25%);
        display: inline-block;
        width: 1.7em;
        height: 1.6em;
        position: relative;
        top: 1px;
        margin-right: 8px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $black;
          box-shadow: inset 0 0 0 4px $light-grey;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $black;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $light-grey;
          border-color: darken($light-grey, 25%);
          background: darken(white, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

input.error {
  border: 1px solid $red;
}

[type='submit'], [type='button'] {
  appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0px;
  cursor: pointer;
  background: none;
  padding: 10px 0px;
}

label.error {
  color: $red;
}

select[name='cel_prefix'],
select[name='buyer_cel_prefix']{
    border-radius: 0px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

[type='checkbox'].error + label{
  color: $red;
  &:before {
    color: $red;
    border: 1px solid $red;
  }

  a{
    color:$red;
  }
}

.image-prefix-flags{
  height:20px;
  @include ml-40;
  @include mr-40;
}
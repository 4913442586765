@mixin muli-regular {
  font-family: 'Muli', Arial, Helvetica, sans-serif;
  font-weight: 400;

}

@mixin muli-medium {
  font-family: 'Muli', Arial, Helvetica, sans-serif;
  font-weight: 500;

}

@mixin muli-semibold {
  font-family: 'Muli', Arial, Helvetica, sans-serif;
  font-weight: 600;
}

@mixin muli-bold {
  font-family: 'Muli', Arial, Helvetica, sans-serif;
  font-weight: 700;
}

@mixin text-outline-1 {
  text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

@mixin text-outline-2 {
  text-shadow:
    -2px -2px 0 #000,  
    2px -2px 0 #000,
    -2px 2px 0 #000,
    2px 2px 0 #000;
}

//space --> margin

//margin-top
@mixin mt-8 {
  margin-top: 8px;
}

@mixin mt-16 {
  margin-top: 16px;
}

@mixin mt-24 {
  margin-top: 24px;
}

@mixin mt-32 {
  margin-top: 32px;
}

@mixin mt-40 {
  margin-top: 40px;
}

@mixin mt-48 {
  margin-top: 48px;
}

@mixin mt-56 {
  margin-top: 56px;
}

@mixin mt-64 {
  margin-top: 64px;
}

//margin-bottom

@mixin mb-8 {
  margin-bottom: 8px;
}

@mixin mb-16 {
  margin-bottom: 16px;
}

@mixin mb-24 {
  margin-bottom: 24px;
}

@mixin mb-32 {
  margin-bottom: 32px;
}

@mixin mb-40 {
  margin-bottom: 40px;
}

@mixin mb-48 {
  margin-bottom: 48px;
}

@mixin mb-56 {
  margin-bottom: 56px;
}

@mixin mb-64 {
  margin-bottom: 64px;
}

//margin-right
@mixin mr-8 {
  margin-right: 8px;
}

@mixin mr-16 {
  margin-right: 16px;
}

@mixin mr-24 {
  margin-right: 24px;
}

@mixin mr-32 {
  margin-right: 32px;
}

@mixin mr-40 {
  margin-right: 40px;
}

@mixin mr-48 {
  margin-right: 48px;
}

@mixin mr-56 {
  margin-right: 56px;
}

@mixin mr-64 {
  margin-right: 64px;
}

//margin-left
@mixin ml-8 {
  margin-left: 8px;
}

@mixin ml-16 {
  margin-left: 16px;
}

@mixin ml-24 {
  margin-left: 24px;
}

@mixin ml-32 {
  margin-left: 32px;
}

@mixin ml-40 {
  margin-left: 40px;
}

@mixin ml-48 {
  margin-left: 48px;
}

@mixin ml-56 {
  margin-left: 56px;
}

@mixin ml-64 {
  margin-left: 64px;
}

//space --> padding

//padding top

@mixin pt-8 {
  padding-top: 8px;
}

@mixin pt-16 {
  padding-top: 16px;
}

@mixin pt-24 {
  padding-top: 24px;
}

@mixin pt-32 {
  padding-top: 32px;
}

@mixin pt-40 {
  padding-top: 40px;
}

@mixin pt-48 {
  padding-top: 48px;
}

@mixin pt-56 {
  padding-top: 56px;
}

@mixin pt-64 {
  padding-top: 64px;
}

//padding bottom

@mixin pb-8 {
  padding-bottom: 8px;
}

@mixin pb-16 {
  padding-bottom: 16px;
}

@mixin pb-24 {
  padding-bottom: 24px;
}

@mixin pb-32 {
  padding-bottom: 32px;
}

@mixin pb-40 {
  padding-bottom: 40px;
}

@mixin pb-48 {
  padding-bottom: 48px;
}

@mixin pb-54 {
  padding-bottom: 56px;
}

@mixin pb-64 {
  padding-bottom: 64px;
}


//padding-right
@mixin pr-8 {
  padding-right: 8px;
}

@mixin pr-16 {
  padding-right: 16px;
}

@mixin pr-24 {
  padding-right: 24px;
}

@mixin pr-32 {
  padding-right: 32px;
}

@mixin pr-40 {
  padding-right: 40px;
}

@mixin pr-48 {
  padding-right: 48px;
}

@mixin pr-56 {
  padding-right: 56px;
}

@mixin pr-64 {
  padding-right: 64px;
}

//padding-left
@mixin pl-8 {
  padding-left: 8px;
}

@mixin pl-16 {
  padding-left: 16px;
}

@mixin pl-24 {
  padding-left: 24px;
}

@mixin pl-32 {
  padding-left: 32px;
}

@mixin pl-40 {
  padding-left: 40px;
}

@mixin pl-48 {
  padding-left: 48px;
}

@mixin pl-56 {
  padding-left: 56px;
}

@mixin pl-64 {
  padding-left: 64px;
}


@mixin basic-transition { transition: all .15s; }

@mixin background-center {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@mixin flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin inline-block {
  display: inline-block;
  vertical-align: top;
}

.not-found-message {
  @include muli-bold;
}

.left-arrow {
  width: 14px;
  transform: rotate(270deg);
  @include mr-8;
}

#cart-container {
  padding-top: 20px;

  .product-brand { text-transform: uppercase; }

  .product-sku {
    font-size: 12px;
    display: block;
  }
}

.cart-items-summary {
  padding: 0;
}

.border-right {
  border-right: 1px solid $black;
  @include breakpoint(small down) { border-right: none; }
}

.quare-btn {
  border: 1px solid $black;
  padding: 0 8px;
  margin-left: 2px;
  cursor: pointer;
  @include basic-transition;

    img { width: 16px; }

    &:hover {
      background-color: $light-grey;
    }
}

.product-total-wrapper {
  height: 100%;
  @include flex-center;

    .product-total {
      font-size: 30px;
      display: block;
      line-height: normal;
      @include breakpoint(medium down) { font-size: 22px; }
    }
}


.cart-update {
  @include breakpoint(small down) {
    max-width: 275px;
    margin: 0 auto;
  }
}

.product-detail {
  @include breakpoint(small down) { text-align: center; }
}

.product-total-wrapper {
  @include breakpoint(small down) { padding: 5px 0 30px; }
}

.cart-summary-detail {
  @include mt-16;

  span {
    color: $black;
    @include muli-regular;
  }

  .cart-summary-detail-total {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  hr {
    border: none;
    border-top: 1px solid $black;
    margin: 8px 0;
  }
}

.burn-coupon-form {
  @include mt-16;
}

.coupon-input-container {
  @include flex-center;

  .input-container {
    padding-right: 0;
  }

  .label-input {
    @include mt-16;
  }

  #coupon-code {
    margin-bottom: 0;
  }
}

.cart-summary-wrapper {
  background-color: $light-grey;
  padding: 20px 25px;
  @include breakpoint(small down) { margin-bottom: 25px; }

  label {
    color: $black;
    @include muli-regular;
  }
}

.carrier-form {
  select {
    @include muli-regular;
  }
}

.btn-coupon {
  font-size: 13px;
  @include breakpoint(small down) { margin: 16px 0; }
}

.empty-cart {
 text-align: right;

 @include breakpoint(medium down) { 
   text-align: left;
  }

}

.cart-detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    @include muli-regular;
    
    &.float-right {
      text-align: end;
    }
  }
}

.links-conditions {
  span {
    @include muli-regular;

    &:hover {
      cursor: default;
    }
  }

  a {
    @include muli-bold;
  }
}
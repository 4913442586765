//cms part

.dropshipping-cta-section {
  background-color: $light-grey;
  padding: 40px;
  @include mt-32;
  @include mb-32;

  p {
    font-size: 18px;
    line-height: 24px;
    @include muli-bold;
  }
}

.dropshipping-list {
  @include muli-semibold;
  font-size: 20px;
  line-height: 32px;
  @include mt-24;
  @include mb-24;
  display: flex;
  align-items: center;
  &:before {
    content: url(/skins/romanelli/images/icons/check.svg);
    width: 20px;
    height: 20px;
    @include mr-16;
  }

}
.dropshipping-advantages-section {
  background-color: $light-grey;
  padding: 40px;
  @include mt-32;
  @include mb-32;
}

.dropshipping-plugin-title {
  font-size: 22px;
  line-height: 32px;
  @include muli-semibold;
  @include mt-16;
  @include mb-16;
  text-align: center;
}

.plugin-list {
  li {
    list-style-type: disc;
    @include pl-16;
    @include ml-24;
    @include mb-8;
    @include muli-semibold;

  }
}

.dropshipping-single-subscription {
  display: flex;
}
//prices
.dropshipping-box {
  align-items: center;
  background-color: $white;
  border: 1px solid $black;
  box-shadow: 5px 3px 11px $paypal-bg-hover;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  @include mt-32;
  @include mb-32;
  padding: 24px;
  text-align: center;
  .box-title {
      font-size: 30px;
      line-height: 40px;
      @include muli-bold;
      @include mb-24;
      color: $blue;
  }
  .total-price, .total-price>span {
    font-size: 50px;
    line-height: 56px;
    @include muli-bold;
  }

  .taxable-price, .taxable-price>span {
    font-size: 30px;
    line-height: 40px;
    @include muli-semibold;
    text-decoration: line-through;
    color: $dark-grey;
}
  .vat-excluded {
    font-size: 12px;
    line-height: 16px;
    @include muli-semibold;
    text-transform: uppercase;
    @include mt-8;
    @include mb-24;
  }
  .terms-and-conditions label {
    font-size: 14px;
    @include mb-16;
    @include mt-16;
    text-align: left;
    white-space: nowrap;
    width: 100%;
  }
  .box-description {
    li{
     @include muli-semibold;
     @include mb-16;

    }
  }
  .box-price {
    display: flex; 
    flex-direction: column;
    justify-content: flex-end;
    margin-top: auto;
  }
}

#dropshipping-tabs {
  .tabs-title {
    width: 33.33%;
    text-align: center;
    @include breakpoint(medium down) { 
      width: 100%;
    }
    a {
      font-size: 16px;
      line-height: 24px;
      @include muli-semibold;
      color: $blue;
      &:focus{
       @include muli-bold;
       background-color: $light-grey;
     }
    }

    >a[aria-selected=true] {
      @include muli-bold;
       background-color: $light-grey;
       color: $blue;
     }
  }
}

.dropshipping-main-featured {
  background-color: $blue;
  color: $white;
  padding: 4px 8px;
}
// color
$black: #241F20;
$black-hover:rgb(46, 41, 42);
$dark-grey: #686868;
$grey-2: #94959A;
$light-grey: #F7F7F7;
$white:  #ffffff;  
$paypal-bg: #eeeeee;
$paypal-bg-hover: #e8e7e7;
$line-color: #94959A;
$border: #94959A;
$blue: #0053B4;
$blue-hover: #0D6CB1;
$red: #DE252A;
$green: #16a228;

// Slick slider
$slick-font-path: '/skins/romanelli/fonts/slick/';
$slick-loader-path: '/skins/romanelli/images/';

// z-indexes
$alert-box-z: 4000;
$header-z: 2000;
$menu-z: 1000;
$under-menu-banner-z: 100;
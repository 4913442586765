// top banner
.top-banner{
  background-color: $red;
  padding: 6px 32px;

    p {
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 14px;
    @include  muli-bold;
    color: $white;
    }
}



// header
header {
  padding: 0;
  z-index: $header-z;
}

.header-sticky {
  background: $white;

  @include breakpoint(medium down) {
    position: sticky;
    top: 0;
    z-index: $header-z;
  }

  &.sticky {
    top: 0;
  }
}

#header-link {
  height: 40px;
  padding: 0;
  color: $white;

  @include breakpoint(medium down) {
    height: 64px;
  }
}

.header-logo-mobile {
  display: none;
  margin: 0 auto;
  @include breakpoint(medium down) {
    display: block;
    height: 64px;
    padding: 8px 0;
  }
}

.header-top-right {
  display: flex;
  justify-content: flex-end;
}

#header-search {
  height: 48px;
}

.header-icon {
  @include ml-16;
  @include inline-block;
  @include basic-transition;
  @include breakpoint (medium down) {
    width: 40px;
    margin: initial;
  }
  
  a {
    @include inline-block;
    span {
      display: inline-block;
      white-space: nowrap;
      @include ml-8;
      @include pt-8;
      @include pb-8;
      @include muli-regular;
  
      span { display: inline; }
    }
  
    div {
      @include inline-block;
      height: 40px;
      width: 16px;
      @include breakpoint (medium down) {
        height: 64px;
        width: 40px;
      }
    
      &.cart-empty {
        width: 40px;
        @include breakpoint (medium down) {
          height: 64px;
        }
      }

      &.cart {
        width: 40px;
        background: $blue;
        @include breakpoint (medium down) {
          height: 64px;
        }
      }
  
      img {
        display: inline-block;
        vertical-align: top;
        height: 16px;
        margin-top: 12px;
        @include breakpoint (medium down) {
          height: 24px;
          margin-left: 8px;
          margin-top: 20px;
        }
      
        &.cart {
          height: 16px;
          margin-left: 12px;
          @include breakpoint (medium down) {
            height: 24px;
            margin-left: 8px;
            margin-top: 20px;
          }
        }
      }
    }
  }

  &:hover {
    opacity: .7;
  }

  &.profile-icon {
    &:hover {
      opacity: 1.0;
    }
  }
}

.dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;

  ul {
    display: none;
    position: absolute;
    z-index: $menu-z;
    background-color: $white;
    border: 1px solid $black;
    top: 35px;
    padding: 8px 24px 8px 16px;

    a:hover {
      color: $blue;
    }
  }

  &:hover {
      ul {
        display: block;
        z-index: $header-z + 1;
      }
  }
}

.language-dropdown {
  @include mr-40;
  @include pt-8;

  ul a {
    color: $black;
    @include muli-regular;
  }

  .flag-image {
    width: 16px;
    position: relative;
    top: -2px;
    margin-right: 7px;
  }

  .sort-image {
    width: 7px;
    margin-left: 5px;
  }
}

.profile-dropdown {

  ul {
    color: $black;
    right: 0;
  }

  ul a, ul input {
    @include muli-regular;
  }

  input {
    border: none;
    cursor: pointer;
    padding: 0px;

      &:hover {
        color: $blue;
      }
  }
}

.mobilemenu-item {
  border-bottom: 1px solid $line-color;
  display: flex !important;
  justify-content: space-between;
}

.search-form {
  width: 100%;
  height: 40px;
  @include inline-block;

  .search-bar {
    height: 40px;
    margin: 0px;
    border: none;
    background: $light-grey;
    box-shadow: none;
    color: $dark-grey;
    @include muli-regular;
    @include pr-40;
    @include pl-8;
    @include inline-block;
  }

  .search-bar::placeholder {
    color: $dark-grey;
    opacity: 1;
  }
}

.search-btn {
  width: 32px;
  min-width: 32px;
  height: 40px;
  background: $black;
  position: absolute;
  right: 0px;

  .search-icon {
    width: 16px;
    right: 8px;
    @include inline-block;
  }

  &.nav {
    position: relative;
    top: 40px;
    right: 32px;
  }
}

.navigation {
  display: flex;

  .nav-logo {
    height: 96px;
    @include inline-block;
    @include mt-8;
    @include mb-8;
    @include mr-8;
  }

  .search-form {
    width: 360px;
    height: 40px;
    margin-left: auto;
    @include mt-40;
    @include ml-8;
    @include inline-block;
    @media screen and (min-width: 900px) and (max-width: 1024px) {
      width: 220px;
    }

    .search-bar {
      width: 360px;
      height: 40px;
      margin: 0px;
      border: none;
      background: $light-grey;
      box-shadow: none;
      color: $dark-grey;
      @include muli-regular;
      @include pr-40;
      @include pl-8;
      @include inline-block;
    }

    .search-bar::placeholder {
      color: $dark-grey;
      opacity: 1;
    }
  }

  .main-menu {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 55%;

    .main-menu-item {
      display: block;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;
      height: 44px;

      .menu-label {
        position: relative;
        top: 16px;
        @include muli-regular;
        @include pb-8;

        &.bold {
          font-weight: bold;
        }
      }

      &:hover {
        cursor: pointer;
        border-bottom: solid $blue;

        .menu-label {
          font-weight: bold;
        }

        .submenu-container {
          display: block;
        }
      }
    }
  }
}

#main-menu {
  position: relative;
}

.submenu-container {
  display: none;
  position: absolute;
  width: 100%;
  background: $white;
  left: 0px;
  right: 0;
  max-width: 1385px;
  margin: 8px auto;
  top: 69px;
  z-index: $menu-z;
  box-shadow: -3px 5px 10px rgba(123, 123, 123, 0.16);

  .row {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background: $white;
  }

  .submenu {
    cursor: default;
  }

  .submenu-title {
    display: block;
    @include muli-bold;
    @include mt-24;
    @include mb-8;

    &.all {
      text-transform: none;
      @include mt-16;
    }
    &.no-margin-top {
      margin-top: 0;
    }
  }

  .submenu-entries {
    display: block;

    li {
      margin: 8px 0;
      @include muli-regular;
    }
  }

  .submenu-image-container {
    padding: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
    width: 100%;
    height: 440px;
  }
}
.menu-columns {
  padding: 0 24px 24px 24px;
  border-right: 1px solid $light-grey;
  min-height: 350px;

  &.first {
    background-color: $light-grey;
    height: 440px;
  }

  &.stacked {
    min-height: auto;
  }
}
.mobile-menu {
  background: $black;
  color: $white;
  position: absolute;
  overflow: scroll;
  width: 270px;
  top: 0px;
  left: -270px;
  height: 100vh;
  @include pt-16;
  @include pl-40;
  @include pr-24;
  @include pb-24;

  .mobile-menu-logo {
    @include pb-16;
  }

  li {
    border-bottom: 1px solid $white;
    @include pt-16;
    @include pb-8;

    .mobile-menu-item {
      display: flex;
      width: 100%;

      span, input {
        color: $white;
        margin-right: auto;
        padding: 0;
        @include muli-semibold;

        &.blue {
          color: $blue;
        }
      }
  
      img {
        margin-left: auto;
        width: 24px;
        height: 24px;

        &.arrow {
          width: 16px;
          height: auto;

          &.open { transform: rotate(180deg); }
        }
      }

      &:not(.mobile-submenu-toggle) {
        &:hover, &:active {
          @include muli-bold;
          color: $black;
        }
      }

      input[type=submit] {
        margin: 0;
        width: 100%;
        text-align: left;
      }
    }

    .mobile-submenu-toggle {
      cursor: pointer;
    }

    .mobile-submenu-arrow {
      transform: rotate(90deg);
    }
  }

  .mobile-submenu {
    display: none;
    @include pt-16;
    @include pl-8;

    li {
      border: none;
      @include pt-8;
      @include pb-8;
    
      .mobile-submenu-item {
        display: flex;
        width: 100%;
    
        span {
          color: $white;
          margin-right: auto;
          @include muli-semibold;
        }
      
        img {
          margin-left: auto;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.mobile-submenu .mobile-submenu li a {
  text-transform: capitalize;
}

.navigation-banner {
  text-align: center;
  background-color: rgba(254, 254, 254, 0.9);
  padding: 6px 0 8px;

  span {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.05em;
    color: $blue;
  }
}


.subscribe-link-fixed {
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
  position: relative;
  top: 4px;
  letter-spacing: 0.03em;
  @media only screen and (max-width: 1200px) {
    display: none;
  }

    &.fixed-btn {
      margin: 0 16px 0 12px;
      background-color: $blue;
      padding: 0 5px;
    }
}

.mobile-logout {
  background: none;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  padding: 16px 0;
}


#mainsearch {
  background: $light-grey;
  border: none;
  box-shadow: none;
  font-size: 16px;
  height: 26px;
  margin: 0;
  width: 100%;
  padding-left: 30px;
}

#mainsearch-mobile {
  background: $light-grey;
  border: none;
  box-shadow: none;
  font-size: 14px;
  height: 48px;
  margin: 0;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 49px;
  padding-left: 13px;
}

#mainsearch-mobile::placeholder {
  color: $dark-grey;
}

.search-icon {
  position: absolute;
  top: 12px;
  width: 24px;
  right: 12px;
}

.search-form { width: 100%; }


// mobile menu
.mobile-menu-toggle {
  width: 32px;
  @include mt-16;
  @include pt-16;
  @include pb-16;
}

.nav-toggle {
  cursor: pointer;
}

.nav-toggle span,
.nav-toggle span:before,
.nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 2px;
  width: 28px;
  background: $white;
  position: absolute;
  display: block;
  content: '';
}

.nav-toggle span:before {
  top: -10px;
}

.nav-toggle span:after {
  bottom: -10px;
}

.nav-toggle span,
.nav-toggle span:before,
.nav-toggle span:after {
  transition: all 200ms ease-in-out;
}

.nav-toggle.active span {
  background-color: transparent;
}

.nav-toggle.active span:before,
.nav-toggle.active span:after {
  top: 0;
}

.nav-toggle.active span:before {
  transform: rotate(45deg);
}

.nav-toggle.active span:after {
  transform: rotate(-45deg);
}

.position {
  position: absolute;
  top: 5px;
  left: 10px;
  transition: all .3s ease;
  z-index: 2;
  @include breakpoint(small down) {
    top: 0px;
  }

    &.top-banner-closed {
      top: 33px;
      @include breakpoint(small down) {
        top: 25px;
      }
    }
}

.position-search {
  top: 64px
}


.main {
  width: 100%;
  position: absolute;
  left: 0;
  transition: .3s ease all;
}

.show-nav .navigation,
.show-nav .main {
  transform: translateX(270px);
}

.show-nav .navigation { overflow-y: auto; }

.show-nav .overlay {
  display: block;
}

.overlay {
  display: none;
  z-index: $menu-z - 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.0);
}

.search-mobile {

    #mainsearch {
      margin-bottom: 0;
      text-align: left;
      font-size: 16px;
      background-color: $white;
      border: 1px solid $blue;
      border-radius: 4px;
    }
}

.mobile-languages {
  margin: 16px 0 64px;

    ul {
      display: flex;
      justify-content: space-between;
    }

    img {
      width: 30px;
      opacity: .5;
    }

    .mobile-active {
      border-bottom: 3px solid $blue;
      padding-bottom: 6px;

        img {
          opacity: 1;
        }
    }
}

/* tasto esci */

.logout-menu {
  text-transform: uppercase;
  font-size: 14px;
  padding: 16px 0 10px;
}


//menu

.first-item-img {
  width: 100%;
  height: 61%;
  margin-top: 15px; 
}

ul.a_z-list {
  li {
  width: 20%;
  /*float: left; */
  display: inline-flex;
  }
}

.menu-all-button {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
      a {
        text-transform: uppercase;
      }
}

.anchor-menu {
    position: absolute;
    left: 0px;
    top: -60px;
}

.top-bar {
  background-color: $black;
  padding: 0 1%;
}
.home-title {
    padding: 0;
    @include mb-8;

    h2 {
        font-size: 35px;
        line-height: 44px;
        @include muli-bold;
    }
}

// banner
.under-menu-banner {
    width: 100%;
    position: relative;
}

#banner-slider {
    height: 400px;
    z-index: $under-menu-banner-z;
}

#banner-arrows {
    max-width: none;
}

.banner-text {
    display: block;
    height: 400px;

    .banner-text-row {
        @include flex-center;
        @include mt-40;

        &.left {
            @include flex-start;

            @include breakpoint(small down) {
                @include flex-center;
            }
        }

        &.right {
            @include flex-end;

            @include breakpoint(small down) {
                @include flex-center;
            }
        }

        .banner-text-row-1 {
            height: 56px;
            font-size: 25px;
            line-height: 30px;
            height: 64px;
            color: $white;
            background: $black;
            @include pl-56;
            @include pr-56;
            @include pt-16;
            @include pb-16;
            @include muli-bold;
            @include breakpoint(medium down) {
                font-size: 16px;
                line-height: 29px;
            }
        }

        .banner-text-row-2 {
            height: 80px;
            font-size: 70px;
            line-height: 88px;
            height: 80px;
            color: white;
            @include flex-center;
            @include text-outline-1;
            @include pt-16;
            @include muli-bold;

            @include breakpoint(medium down) {
                font-size: 35px;
                line-height: 44px;
            }

            &.large {
                font-size: 40px;
                line-height: 48px;
                @include breakpoint(medium down) {
                    font-size: 22px;
                    line-height: 32px;
                }
            }
        }
    }
}

.banner-image-secondary {
    width: 480px;
}

.arrow-container {
    cursor: pointer;
    background: $white;
    border: 1px solid $black;
    width: 40px;
    height: 40px;
    @include flex-center;

    img {
        width: 12px;
    }
}

.left-slider-arrow {
    z-index: $under-menu-banner-z + 1;
    @include flex-center;

    .arrow-container {
        img {
            transform: rotate(270deg);
        }
    }

    &.edge {
        justify-content: flex-start;
    }
}

.right-slider-arrow {
    z-index: $under-menu-banner-z + 1;
    @include flex-center;

    .arrow-container {
        img {
            transform: rotate(90deg);
        }
    }

    &.edge {
        justify-content: flex-end;
    }
}

.banner-slide {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-position: center center;
    height: 400px;
    width: 100%;
    max-width: 100%;

    @include breakpoint(small down) {
        background-position: center center;
    }
}

.home-row {
    @include mt-56;
    @include mb-56;

    &.top,
    &.slider {
        @include mt-56;
    }

    .columns {
        @include breakpoint(medium down) {
            @include mt-16;
        }
    }

    @include breakpoint(medium down) {
        @include ml-8;
        @include mr-8;
    }
}

.background-image {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    width: 100%;
    max-width: 100%;

    &.show-background-for-mobile {
        @include breakpoint(medium down) {
            img {
                opacity: 1;
            }
        }
    }

    &.hide-background-for-mobile {
        @include breakpoint(medium down) {
            background-color: rgba($color: $white, $alpha: 1);
            background-blend-mode: lighten;
        }
    }

    &.centered-image {
        background-size: contain;
        background-position: 0% 50%;
    }
}

.login-subscribe-box {
    margin-left: auto;
    border: 2px solid $black;
    background: $white;
    @include pt-40;
    @include pb-40;
    @include pl-24;
    @include pr-24;
    @include breakpoint(medium down) {
        width: 100%;
        @include mt-16;
    }

    .bullet-points {
        @include flex-center;

        ul {
            @include pt-16;
            @include pb-16;

            li {
                /*list-style-image: url('/skins/romanelli/images/icons/check.svg');*/
                @include muli-regular;
                @include pt-8;
                @include pb-8;
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.category-banner-container {
    padding: 0;
    @include mt-32;

    &.left {
        @include pr-8;
        @include breakpoint(medium down) {
            padding-right: 0;
        }
    }

    &.right {
        @include pl-8;
        @include breakpoint(medium down) {
            padding-left: 0;
        }
    }
}

.category-banner-box {
    @include pt-48;
    @include pl-40;
    @include pr-40;
    @include pb-40;
    &.blog {
        padding: 24px;
    }

    .title,
    .subtitle,
    .description {
        margin-left: auto;

        &.width-33 {
            width: 33%;
            @include breakpoint(medium down) {
                width: 100%;
            }
        }

        &.width-66 {
            width: 66%;
            @include breakpoint(medium down) {
                width: 100%;
            }
        }
    }

    .subtitle,
    .description {
        @include muli-regular;
    }

    .btn {
        margin-left: auto;
    }

    .title,
    .subtitle,
    .description,
    .btn {
        @include breakpoint(medium down) {
            margin-right: auto;
        }
    }

    .title,
    .subtitle,
    .description {
        @include breakpoint(medium down) {
            color: $white;
            text-shadow: 0px 0px 16px $black;
        }
    }
}

.category-selector {
    padding: 0px;
    @include breakpoint(medium down) {
        display: flex;
        justify-content: center;
    }

    h3 {
        display: inline;
        @include breakpoint(medium down) {
            font-size: 20px;
        }
    }

    li {
        display: inline;

        .link {
            cursor: pointer;
            color: $dark-grey;
            @include muli-regular;

            &.selected {
                cursor: default;
                color: $black;
                @include muli-bold;
            }
        }

        .link:hover {
            color: $black;
            border-bottom: solid $blue;

            &.selected {
                border-bottom: none;
            }
        }

        .separator {
            @include muli-regular;
            @include ml-16;
            @include mr-16;
            @include breakpoint(medium down) {
                @include ml-8;
                @include mr-8;
            }
        }
    }
}

.products-slider-container,
.brands-slider-container {
    padding: 0px;
    position: relative;
    @include mt-32;
}

.brands-slider-container {
    .title {
        @include muli-bold;
    }
}

.products-slider {
    display: flex;

    .product-slide,
    .brand-slide {
        .picture {
            width: 100%;
        }

        .arrow {
            transform: rotate(90deg);
            display: inline;
        }

        .product-slide {
            font-size: 14px;
            line-height: 24px;
            .product-slide-price {
                font-size: 16px;
                @include mt-8;
            }
            &.price {
                font-size: 16px;
                @include mt-8;
            }
        }

        .product-slide-name {
            @include mt-8;
            @include muli-regular;
        }

        .product-slide-price,
        .product-slide-brand {
            @include mt-8;
            @include muli-bold;
        }
    }
}

.slider-arrows {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    pointer-events: none;

    @include breakpoint(small down) {
        display: none;
    }

    .arrow-container {
        pointer-events: initial;
    }
}

// workaround to make hidden slick sliders work
.product-slider {
    display: block !important;
    height: auto;

    &.hidden {
        display: block !important;
        height: 0;
        overflow: hidden;
    }
}

.magazine-container {
    @include breakpoint(medium down) {
        @include ml-8;
        @include mr-8;
    }

    .magazine-box-title {
        @include muli-bold;
    }

    .magazine-box {
        @include mt-32;

        img {
            display: block;
            width: 100%;
        }

        .magazine-title {
            font-size: 18px;
            line-height: 23px;
            @include muli-semibold;
        }

        .magazine-link {
            @include ml-8;
        }

        .magazine-text,
        .magazine-link {
            font-size: 14px;
            line-height: 24px;
            @include muli-regular;
        }
    }
}

.home-bottom {
    .logo {
        display: flex;
        img {
            width: 80px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .site-name {
        @include mt-16;
        @include muli-bold;
    }

    .site-description {
        @include mt-24;
        @include muli-regular;
    }
}

.newsletter-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    width: 100%;
    max-width: none;
    background-image: url("/skins/romanelli/images/home/newsletter.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    .input-box {
        width: 100%;

        .newsletter-title {
            @include muli-bold;
        }

        .newsletter-description {
            @include mt-16;
            @include muli-regular;
        }
    }
}

#newsletter-subscribe {
    @include mt-24;

    .email-input {
        background: $white;
        color: $black;
        border-radius: 7px;
        height: 44px;
        @include mr-16;
        @include muli-regular;
    }

    .email-input::placeholder {
        color: $black;
        opacity: 1;
        @include muli-regular;
    }

    button[type="submit"] {
        padding: 16px;
        @include breakpoint(medium down) {
            @include mt-40;
        }
    }

    .btn-next {
        text-transform: capitalize;
        width: max-content;
    }
}

#best-brands {
    img {
        padding: 0 32px;
    }
    &.slick-initialized.slick-slider {
        button.slick-prev:before {
            content: url(/skins/romanelli/images/icons/arrow-slider-left.svg);
            @include breakpoint(medium down) {
                display: none;
            }
        }

        button.slick-next:before {
            content: url(/skins/romanelli/images/icons/arrow-slider-right.svg);
            @include breakpoint(medium down) {
                display: none;
            }
        }

        .slick-list,
        .slick-slider {
            padding: 0 32px;
        }
    }

    .slick-next,
    .slick-prev {
        width: 40px;
        top: 32%;
        z-index: 1;
    }
    .slick-next {
        right: 0px;
    }

    .slick-prev {
        left: 0px;
    }
}

.slider-title {
    h2,
    h3 {
        font-size: 25px;
        line-height: 30px;
        @include muli-bold;
    }
}

.newsletter-container {
    background-color: #ffffffda;
    padding: 24px;
}

// Loader
.loader {
    color: #ffffff;
    font-size: 20px;
    margin: 100px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
@-webkit-keyframes load4 {
    0%,
    100% {
        box-shadow:
            0 -3em 0 0.2em,
            2em -2em 0 0em,
            3em 0 0 -1em,
            2em 2em 0 -1em,
            0 3em 0 -1em,
            -2em 2em 0 -1em,
            -3em 0 0 -1em,
            -2em -2em 0 0;
    }
    12.5% {
        box-shadow:
            0 -3em 0 0,
            2em -2em 0 0.2em,
            3em 0 0 0,
            2em 2em 0 -1em,
            0 3em 0 -1em,
            -2em 2em 0 -1em,
            -3em 0 0 -1em,
            -2em -2em 0 -1em;
    }
    25% {
        box-shadow:
            0 -3em 0 -0.5em,
            2em -2em 0 0,
            3em 0 0 0.2em,
            2em 2em 0 0,
            0 3em 0 -1em,
            -2em 2em 0 -1em,
            -3em 0 0 -1em,
            -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow:
            0 -3em 0 -1em,
            2em -2em 0 -1em,
            3em 0em 0 0,
            2em 2em 0 0.2em,
            0 3em 0 0em,
            -2em 2em 0 -1em,
            -3em 0em 0 -1em,
            -2em -2em 0 -1em;
    }
    50% {
        box-shadow:
            0 -3em 0 -1em,
            2em -2em 0 -1em,
            3em 0 0 -1em,
            2em 2em 0 0em,
            0 3em 0 0.2em,
            -2em 2em 0 0,
            -3em 0em 0 -1em,
            -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow:
            0 -3em 0 -1em,
            2em -2em 0 -1em,
            3em 0 0 -1em,
            2em 2em 0 -1em,
            0 3em 0 0,
            -2em 2em 0 0.2em,
            -3em 0 0 0,
            -2em -2em 0 -1em;
    }
    75% {
        box-shadow:
            0em -3em 0 -1em,
            2em -2em 0 -1em,
            3em 0em 0 -1em,
            2em 2em 0 -1em,
            0 3em 0 -1em,
            -2em 2em 0 0,
            -3em 0em 0 0.2em,
            -2em -2em 0 0;
    }
    87.5% {
        box-shadow:
            0em -3em 0 0,
            2em -2em 0 -1em,
            3em 0 0 -1em,
            2em 2em 0 -1em,
            0 3em 0 -1em,
            -2em 2em 0 0,
            -3em 0em 0 0,
            -2em -2em 0 0.2em;
    }
}
@keyframes load4 {
    0%,
    100% {
        box-shadow:
            0 -3em 0 0.2em,
            2em -2em 0 0em,
            3em 0 0 -1em,
            2em 2em 0 -1em,
            0 3em 0 -1em,
            -2em 2em 0 -1em,
            -3em 0 0 -1em,
            -2em -2em 0 0;
    }
    12.5% {
        box-shadow:
            0 -3em 0 0,
            2em -2em 0 0.2em,
            3em 0 0 0,
            2em 2em 0 -1em,
            0 3em 0 -1em,
            -2em 2em 0 -1em,
            -3em 0 0 -1em,
            -2em -2em 0 -1em;
    }
    25% {
        box-shadow:
            0 -3em 0 -0.5em,
            2em -2em 0 0,
            3em 0 0 0.2em,
            2em 2em 0 0,
            0 3em 0 -1em,
            -2em 2em 0 -1em,
            -3em 0 0 -1em,
            -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow:
            0 -3em 0 -1em,
            2em -2em 0 -1em,
            3em 0em 0 0,
            2em 2em 0 0.2em,
            0 3em 0 0em,
            -2em 2em 0 -1em,
            -3em 0em 0 -1em,
            -2em -2em 0 -1em;
    }
    50% {
        box-shadow:
            0 -3em 0 -1em,
            2em -2em 0 -1em,
            3em 0 0 -1em,
            2em 2em 0 0em,
            0 3em 0 0.2em,
            -2em 2em 0 0,
            -3em 0em 0 -1em,
            -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow:
            0 -3em 0 -1em,
            2em -2em 0 -1em,
            3em 0 0 -1em,
            2em 2em 0 -1em,
            0 3em 0 0,
            -2em 2em 0 0.2em,
            -3em 0 0 0,
            -2em -2em 0 -1em;
    }
    75% {
        box-shadow:
            0em -3em 0 -1em,
            2em -2em 0 -1em,
            3em 0em 0 -1em,
            2em 2em 0 -1em,
            0 3em 0 -1em,
            -2em 2em 0 0,
            -3em 0em 0 0.2em,
            -2em -2em 0 0;
    }
    87.5% {
        box-shadow:
            0em -3em 0 0,
            2em -2em 0 -1em,
            3em 0 0 -1em,
            2em 2em 0 -1em,
            0 3em 0 -1em,
            -2em 2em 0 0,
            -3em 0em 0 0,
            -2em -2em 0 0.2em;
    }
}

//dropshipping

.dropshipping-section-main-containter {
    background-color: $light-grey;
    padding: 24px 16px;
    @include breakpoint(small down) {
        padding: 0px 16px;
    }
}
.dropshipping-section-text-container {
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: baseline;
    align-content: center;
}

.instagram-image {
    margin: 16px;
    object-fit: cover;
    width: 100%;
}

.outer-box {
    height: 0;
    overflow: hidden;
    padding-top: 33.3333333333%;
    background: white;
    position: relative;

    @include breakpoint(small down) {
        padding-top: 50%;
    }
}

.inner-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
}
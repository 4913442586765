#checkout-wrapper {
  @include pt-40;
  @include pb-40;

  h2 {
    @include muli-bold;
    @include breakpoint(small down) {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

.checkout-step-title {
  border: 1px solid $grey-2;
  background-color: $white;
  color: $blue;
  padding: 8px 14px 7px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 12px 0;
  font-size: 14px;
}

.checkout-step-title-next {
  border: 1px solid $grey-2;
  padding: 8px 14px 7px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 12px 0;
  font-size: 14px;
  opacity: .3;
}

.checkout-step-title-done {
  border: 1px solid $grey-2;
  padding: 8px 14px 7px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 12px 0;
  font-size: 14px;
}

.cart-summary-container {
  background-color: $light-grey;
  padding: 0;

    .checkout-step-title {
      border-color: $blue;
    }

    .cart-summary {
      padding: 0 24px 16px;
      font-size: 14px;
    }
}

#billing-form-btn {
  @include breakpoint(small down) { margin-bottom: 10px; }
}

.checkout-container {
  @include pt-40;
  @include pb-40;

  h1 {
    @include mb-40;
    @include muli-bold;
  }

  .return-msg {
    @include mt-16;
    @include mb-16;
    @include muli-regular;
  
    a {
      text-decoration: underline;
      @include muli-bold;
    }
  }
}

.additional-fee {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  margin: -7px 0 12px;
}

.container-paypal-additional-cost {
  display: flex;
  align-items: center;
  & span {
    font-size: 14px;
    @include muli-regular;
  }
}

.order-zero-amount {
  @include mb-8;
  @include muli-semibold;
}

.btn.checkout {
  width: 100%;
  padding: 16px;
  margin-bottom: 0;
  @include mt-8;
}

#paypalBraintreeButton {
  border: 2px solid $black !important;
  border-radius: 6px !important;
  @include mt-8;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $black;
  list-style-type: none;
  outline: 0 !important;
  @include muli-regular;
}

body {
  overflow-x: hidden;
  @include muli-regular;

    &.show-nav { overflow-y: hidden; }
}

.row {
  max-width: 90rem;
  &.centered {
    max-width: 70rem;
  }
}

a { text-decoration: none; }

.cursor-pointer {
  cursor: pointer;
}

.wrapper {
  min-height: calc(100vh - 550px);
}

.relative { position: relative; }

.width-100 { width: 100%; }
.height-100 { height: 100%; }


.list-unstyled { list-style: none; }

.slick-slide img.link-arrow { display: inline-block; }

.slick-slider .slick-dots li button:before {
  font-size: 16px;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.back-icon {
  width: 6px;
  margin-top: -3px;
  margin-right: 7px;
}

hr {
  border: 0;
  border-top: 1px solid $line-color;
}

.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.direction-column {
  flex-direction: column;
}
.dummy-image {
  vertical-align: top;
  width: 100%;
  opacity: 0;
}

.hide {
  display: none;
}

.sticky {
  position: sticky;
}

// margin top
.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}
.mt-32 {
  margin-top: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-56 {
  margin-top: 56px;
}


// margin bottom
.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-56 {
  margin-bottom: 56px;
}

// margin right
.mr-8 {
  margin-right: 8px;
}

// arrow 
.arrow {
  width: 9px;
}

.mb-24-mobile {
  @include breakpoint(small down) { 
    margin-bottom: 24px;
  }
}

.mb-16-tablet {
  @include breakpoint(medium down) { 
    margin-bottom: 16px;
  }
}

.mt-16-tablet {
  @include breakpoint(medium down) { 
    margin-top: 16px;
  }
}

.mt-0-mobile {
  @include breakpoint(small down) { 
    margin-top: 0;
  }
}

.mb-0-mobile {
  @include breakpoint(small down) { 
    margin-bottom: 0;
  }
}

option[selected] {
    background-color: $white;
}

.ph-16 {
  padding: 0 16px;
}
h1, .title-1 {
  font-size: 70px;
  font-weight: 700;
  line-height: 72px;
}

h2, .title-2 {
  font-size: 35px;
  font-weight: 700;
  line-height: 40px;

  &.regular {
    font-weight: 400;
  }
}

h3, .title-3{
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
  &.regular {
    font-weight: 400;
  }
}

h4, .title-4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  }

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}


// colors
.white { color: $white; }
.blue { color: $blue; }
.red { color: $red;}


// Transform
.upper { 
  text-transform: uppercase; 
}
.capitalize { 
  text-transform: capitalize; 
}
.txt-center { 
  text-align: center;
 }
.txt-right { 
  text-align: right; 
}
.txt-left { 
  text-align: left; 
}
.underline { 
  text-decoration: underline; 
}
.line {
  text-decoration: line-through;
}
.bold {
  @include muli-bold;
}

.semibold {
  @include muli-semibold;
}

.title-3-tablet {
  @include breakpoint(medium down) { 
    font-size: 25px;
    font-weight: 700;
    line-height: 32px;
  }
}
.body-1 {
  font-size: 16px;
  line-height: 24px;
}

.body-1-mobile {
  @include breakpoint(small down) { 
    font-size: 16px;
    line-height: 24px;
  }
}